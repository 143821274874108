<template>
  <div id="lh-table">
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-house-model-create"
    />
    <custom-table 
      ref="table" 
      :max-items="itemsPerPage" 
      :data="items" 
      pagination 
      :searchKeys="searchKeys"
      search>
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <AddNewContent
          name="property-plan-master-gallery-homesell-create"
          button-label="เพิ่มรูปบ้านตกแต่งพร้อมขาย"
        />
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page"
        /> 
      </div>
      <template slot="thead">
        <vs-th sort-key="name">ชื่อโครงการ</vs-th>
        <vs-th sort-key="project_title">ชื่อ folder</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td custom-table--td" @click.stop="editData(tr.id)">{{ tr.project_title }}</td>
            <td class="td custom-table--td" @click.stop="editData(tr.id)">{{ tr.name }}</td>
            <td class="td custom-table--td" @click.stop="editData(tr.id)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <feather-icon
                class="cursor-pointer"
                icon="CopyIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="duplicateContent(tr.id)"
              />
              <router-link
                :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                class="pt-2 color-text-link"
              >
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </router-link>
              <feather-icon
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2 cursor-pointer"
                @click.stop="deleteData(tr.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useMasterGalleryHouseSell from '@/use/useMasterGalleryHouseSell'
import useNotify from '@/use/useNotify'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import { v4 as uuidv4 } from 'uuid'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'MasterGalleryHomesellListView',
  components: {
    LhDropdownPerPage,
    AddNewContent,
    PopupLanguage,
    CustomTable
  },
  setup: (props, ctx) => {
    const crudFunction = useCrud(ctx, 'houseSellGallery')
    const { updatedAtDatetimeFormat } = useDatepicker()
    crudFunction.routePrefix.value = 'property-plan-master-gallery-homesell'

    const {
      error: notifyError,
    } = useNotify(ctx)

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const {
      formData,
      prepareFormData,
      serializeFormData,
      getProjectList
    } = useMasterGalleryHouseSell(ctx)

    const projectList = computed(() => ctx.root.$store.state.project.projects)

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      const currentLang = ctx.root.$store.state.locale.currentLang

      const result = await ctx.root.$store.dispatch(
        'houseSellGallery/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      await getProjectList(currentLang)

      const oldProjectList = [
        ...projectList.value
      ]

      const files = formData.house_sell_gallery_files

      const newFiles = files.map((file) => {
        const randomUuid = uuidv4()
        return {
          ...file,
          image_uuid: randomUuid
        }
      })

      formData.house_sell_gallery_files = newFiles

      const oldProject = oldProjectList.find((project) => project.uuid === formData.project_house_sell_gallery_links)

      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      await getProjectList()

      if (isEmpty(projectList.value)) return notifyError({ text: 'ไม่มีโครงการในภาษาที่คัดลอก กรุณาคัดลอกโครงการก่อน' })

      const newProject = projectList.value.find((project) => project.id === oldProject.id)

      if (newProject) formData.project_house_sell_gallery_links = newProject.uuid
      else {
        await ctx.root.$vs.loading.close()
        return notifyError({ text: 'ไม่มีโครงการในภาษาที่คัดลอก กรุณาคัดลอกโครงการและลองอีกครั้ง' })
      }

      const duplicateData = omit(formData, ['uuid', 'id', 'revision_id'])

      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      duplicateData.created_by = email
      duplicateData.updated_by = email

      const duplicateUuid = await ctx.root.$store.dispatch('houseSellGallery/duplicateItem', serializeFormData(duplicateData, langToDuplicationContent))
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: duplicateUuid
        },
      })
      
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    return {
      ...crudFunction,
      items,
      updatedAtDatetimeFormat,
      handlerDuplicateContent,
      searchKeys: ['project_title', 'name']
    }
  },
}
</script>
